import memoize, { Options } from "micro-memoize";
import { useRef } from "react";

/**
 * Returns a memoized version of `func` for the lifetime of the component.
 * Only the `func` and `opts` provided on first render will have an effect.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- need any for generic function type
export function useMicroMemoize<T extends (...args: any[]) => any>(
  func: T,
  opts: Options<T> = {},
): T {
  const ref = useRef(null as unknown as T);
  if (ref.current == null) {
    ref.current = memoize(func, opts);
  }
  return ref.current;
}
